@use 'src/src/app/scss/abstracts' as *;

.vacancies {
  $block-name: &;

  //   &#{$block-name} {
  //   }

  &__intro-text {
    padding-top: 20px;
    text-align: center;
    color: rgba($color-semantic-third, 0.85);

    @include mq($until: md) {
      padding-top: 0;
    }
  }

  &__roles-title {
    padding-top: 70px;
    text-align: center;
    padding-bottom: 45px;

    @include mq($until: md) {
      padding-top: 45px;
      padding-top: 0;
      padding-bottom: 22px;
    }
  }
}
