@use 'src/src/app/scss/abstracts' as *;

.pagination {
  $block-name: &;

  @include reset-text-indents;

  display: flex;
  flex-wrap: nowrap;
  user-select: none;

  button {
    @include reset-btn;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    transition: color $transition-fast;
    color: $color-base-purple-200;
    font-family: $font-family-main;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    border-radius: 50%;

    @include mq($until: md) {
      font-size: 24px;
      width: 30px;
      height: 30px;
    }

    &:hover {
      background: $color-base-purple-900;
      color: $color-base-white-100;
    }

    &:active {
      color: $color-base-purple-200;
      background-color: transparent;
    }

    &[disabled] {
      display: none !important;
    }

    svg {
      flex: 0 0 auto;
      stroke-width: 1.5;

      @include mq($until: md) {
        width: 17px;
        height: 17px;
      }
    }
  }

  &__container {
    margin-left: 6px;
    margin-right: 6px;

    @include mq($until: md) {
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  &__list {
    @include unlist;

    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    li {
      margin-left: 3px;
      margin-right: 3px;

      @include mq($until: md) {
        margin-left: 5px;
        margin-right: 5px;
      }

      &.previous,
      &.next {
        display: none !important;
      }

      &.active {
        a {
          background-color: $color-base-purple-200 !important;
          color: $color-base-black-200 !important;
        }
      }

      /* stylelint-disable-next-line no-descending-specificity */
      a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        transition: all $transition-fast;
        color: $color-base-purple-200;
        border-radius: 50%;
        text-align: center;
        font-family: $font-family-main;
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;

        @include mq($until: md) {
          font-size: 24px;
          width: 30px;
          height: 30px;
        }

        &:hover {
          background: $color-base-purple-900;
          color: $color-base-white-100;
        }

        &:active {
          color: $color-base-purple-200;
          background-color: transparent;
        }
      }
    }
  }
}
