@use 'src/src/app/scss/abstracts' as *;
@use 'sass:color';

.features-carousel {
  $block-name: &;

  //   &#{$block-name} {
  //   }

  overflow: hidden;

  &__inner-wrap {
    position: relative;
    margin-left: -6px;
    margin-right: -6px;
    z-index: 0;
    padding-top: 70px;
    padding-bottom: 70px;

    @include mq($until: md) {
      padding-top: 50px;
      padding-bottom: 55px;
      margin-left: -3px;
      margin-right: -3px;
    }
  }

  &__inner,
  swiper-container {
    display: flex !important;
    flex-wrap: nowrap !important;
  }

  &__cell,
  swiper-slide {
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto !important;
    flex-direction: column;
    flex-grow: 1;
    width: 30% !important;
    padding-left: 6px;
    padding-right: 6px;

    @include mq($until: 1500px) {
      width: 40% !important;
    }

    @include mq($until: 1150px) {
      width: 70% !important;
    }

    @include mq($until: md) {
      padding-left: 3px;
      padding-right: 3px;
    }
  }

  &__nav {
    @include reset-text-indents;
    @include reset-btn;

    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    top: 50%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: $color-base-black-200;
    color: $color-base-purple-200;
    margin-top: -25px;
    transition: background-color $transition-fast;

    &:hover {
      background-color: lighten($color-base-black-200, 10%);
    }

    &:active {
      background: $color-base-black-200;
    }

    @include mq($until: sm) {
      display: none !important;
    }

    &.swiper-button-disabled {
      pointer-events: none !important;
      background-color: $color-base-black-100 !important;

      svg {
        opacity: 0.3 !important;
      }
    }

    &.swiper-button-lock {
      display: none !important;
    }

    &--left {
      left: 18px;

      @include mq($until: md) {
        left: 12px;
      }
    }

    &--right {
      right: 18px;

      @include mq($until: md) {
        right: 12px;
      }
    }
  }
}
