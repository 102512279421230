@use 'src/src/app/scss/abstracts' as *;

.product-page {
  $block-name: &;

  padding-top: 40px;
  padding-bottom: 120px;

  @include mq($until: lg) {
    padding-top: 0;
  }

  @include mq($until: md) {
    padding-bottom: 70px;
  }

  //   #{$block-name} {
  //   }

  &__main {
    padding-bottom: 135px;

    @include mq($until: md) {
      padding-bottom: 75px;
    }
  }

  &__image {
    border-radius: $border-radius-s;
    overflow: hidden;

    @include mq($until: md) {
      border-radius: $border-radius-2xs;
    }
  }

  &__grid {
    display: flex;
    gap: 0 20px;

    @include mq($until: xl) {
      flex-direction: column;
    }
  }

  &__cell {
    flex: 0 0 340px;
    padding-top: 120px;

    @include mq($until: 1740px) {
      padding-top: 80px;
    }

    @include mq($until: xl) {
      padding-top: 0;
      flex: 0 1 auto;
    }

    &--large {
      padding-top: 0;
      flex: 1 1 auto;
      padding-left: 40px;
      padding-right: 40px;

      @include mq($until: 1740px) {
        padding-left: 0;
        padding-right: 0;
      }
    }

    &--first {
      @include mq($until: xl) {
        order: 2;
      }
    }

    &--second {
      @include mq($until: xl) {
        order: 0;
        width: 100%;
        max-width: 890px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 80px;
      }

      @include mq($until: md) {
        margin-bottom: 35px;
      }
    }

    &--third {
      @include mq($until: xl) {
        order: 1;
        margin-bottom: 80px;
      }

      @include mq($until: md) {
        margin-bottom: 50px;
      }
    }
  }

  &__description {
    color: $color-base-gray-500;
    text-align: center;
  }

  &__related-products {
  }

  &__title {
    text-align: center;
    margin-bottom: 15px;

    @include mq($until: sm) {
      margin-bottom: 10px;
    }

    .title {
      @include mq($until: md) {
        font-size: 36px;
        font-weight: $font-weight-l;
      }

      @include mq($until: sm) {
        font-size: 24px;
        line-height: 27px;
      }
    }
  }

  &__info {
  }
}
