@use 'src/src/app/scss/abstracts' as *;

.article-page {
  $block-name: &;

  //   &#{$block-name} {
  //   }

  &__intro {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 100px;

    @include mq($until: 'xl') {
      display: block;
      padding-bottom: 40px;
    }

    @include mq($until: lg) {
      padding-bottom: 0;
    }
  }

  &__intro-image {
    flex: 0 0 auto;
    width: 474px;
    height: 633px;
    border-radius: $border-radius-m;
    overflow: hidden;

    @include mq($until: md) {
      flex: 1 1 auto;
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }

  &__intro-info {
    margin-top: 40px;

    @include mq($until: md) {
      margin-top: 30px;
    }
  }

  &__intro-info-item {
    display: inline-flex;
    margin: 7.5px;
    border: 2px solid rgba($color-semantic-third, 0.2);
    padding: 11px 20px;
    font-size: 24px;
    font-style: normal;
    font-weight: $font-weight-m;
    line-height: 27px;
    letter-spacing: -0.48px;
    border-radius: 50px;

    @include mq($until: md) {
      margin: 4px;
      padding: 2px 14px;
      font-size: 13px;
      letter-spacing: -0.26px;
      border-width: 1px;
    }
  }

  &__intro-info-list {
    @include unlist;
    @include reset-text-indents;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -7.5px;
    color: rgba($color-semantic-third, 0.85);

    @include mq($until: md) {
      margin: -4px;
    }
  }

  &__intro-left {
    @include mq($until: 'xl') {
      display: flex;
      justify-content: center;
    }
  }

  &__intro-right {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-left: 50px;
    margin-right: 50px;

    @include mq($until: 'xl') {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__intro-right-inner {
  }

  &__intro-title {
    color: $color-base-purple-200;

    .title {
      padding-bottom: 20px;
    }
  }

  &__content {
    color: rgba($color-semantic-third, 0.85);
  }

  &__related-articles {
    padding-top: 140px;

    @include mq($until: lg) {
      padding-top: 100px;
    }
  }

  &__share {
    padding-top: 90px;

    @include mq($until: md) {
      padding-top: 60px;
    }
  }

  &__share-title {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    text-align: center;
  }

  &__share-button {
    display: flex;
    justify-content: center;
  }
}
