@use 'src/src/app/scss/abstracts' as *;

.studio-page-main {
  $block-name: &;

  //   &#{$block-name} {
  //   }

  &__intro {
    margin-bottom: 80px;

    @include mq($until: md) {
      margin-bottom: 40px;
    }
  }

  &__intro-title {
    padding-bottom: 80px;

    @include mq($until: md) {
      padding-bottom: 50px;
    }

    @include mq($until: sm) {
      .title {
        font-size: 24px;
        font-weight: $font-weight-l;
        line-height: $font-line-m;
      }
    }
  }

  &__gallery {
  }

  &__partners {
    padding-top: 140px;

    @include mq($until: md) {
      padding-top: 67px;
    }
  }

  &__last-article {
    padding-top: 130px;

    @include mq($until: md) {
      padding-top: 90px;
    }
  }

  &__contacts {
    padding-top: 110px;
  }

  &__contacts-title {
    text-align: center;
    color: $color-base-purple-200;
  }

  &__contacts-button {
    text-align: center;
    padding-top: 60px;

    @include mq($until: md) {
      padding-top: 40px;
    }
  }
}
