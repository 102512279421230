@use 'src/src/app/scss/abstracts' as *;

$header-height: 81px;
$header-height-mobile: 67px;

.header {
  $block-name: &;

  //   #{$block-name} {
  //   }

  @include reset-text-indents;

  padding-top: 17px;
  padding-bottom: 17px;
  display: flex;
  align-items: center;
  height: $header-height;

  @include mq($until: lg) {
    height: $header-height-mobile;
    padding: 15px 48px 15px 7px;
  }

  &__buttons-group {
    display: flex;
    flex-wrap: nowrap;
    margin-left: auto;
    align-items: center;
  }

  &__buttons-item {
    margin-left: 4px;
    margin-right: 4px;

    @include mq($until: sm) {
      margin-left: 1px;
      margin-right: 1px;
    }

    &--burger {
      display: none;
      position: fixed;
      z-index: $z-index-mobile-navigation-button;
      top: 9px;
      right: 10px;
      width: 48px;
      height: 48px;
      margin-right: 0;
      background-color: rgba($color-semantic-main, 0.6);
      border-radius: 4px;

      @include mq($until: lg) {
        display: block;
      }
    }

    &--language-switcher {
      @include mq($until: lg) {
        display: none;
      }
    }
  }

  &__inner {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  &__logo {
    flex: 0 0 auto;
  }

  &__logo-link {
    display: inline-flex;
    position: relative;

    img {
      @include mq($until: lg) {
        width: 106px;
        height: 36px;
      }
    }
  }

  &__navigation {
    margin-left: 62px;

    @include mq($until: xl) {
      margin-left: 32px;
    }

    @include mq($until: lg) {
      display: none;
    }
  }

  &__navigation-item {
  }

  &__navigation-link {
    color: rgba($color-semantic-third, 0.9);
    font-family: $font-family-main;
    font-size: 36px;
    font-style: normal;
    font-weight: $font-weight-l;
    line-height: $font-line-m;
    letter-spacing: -1.08px;
    text-decoration: none;
    padding: 3px 16px;
    border-radius: $border-radius-2xs;
    transition: all $transition-fast;

    &:hover {
      background: $color-base-purple-900;
      color: $color-semantic-third;
    }

    &:active {
      color: rgba($color-semantic-third, 0.9);
      background-color: $color-semantic-main;
    }

    &--active {
      color: $color-base-gray-500 !important;
      background-color: transparent !important;
    }
  }

  &__navigation-list {
    @include unlist;

    display: flex;
    flex-wrap: nowrap;
  }
}
