/* stylelint-disable no-descending-specificity */
@use 'src/src/app/scss/abstracts' as *;

.editor {
  $block-name: &;

  //   &#{$block-name} {
  //   }

  .title {
    &--tag-h1 {
    }

    &--tag-h2 {
      margin-top: 100px;
      margin-bottom: 70px;

      @include mq($until: md) {
        margin-top: 40px;
        margin-bottom: 30px;
      }
    }

    &--tag-h3 {
      line-height: 1;
    }

    &--tag-h3,
    &--tag-h4,
    &--tag-h5,
    &--tag-h6 {
      margin-top: 60px;
      margin-bottom: 50px;

      @include mq($until: md) {
        margin-top: 30px;
        margin-bottom: 20px;
      }
    }
  }

  .text,
  .editor-list {
    margin-top: 1.389em;
    margin-bottom: 1.389em;

    @include mq($until: md) {
      margin-top: 1.429em;
      margin-bottom: 1.429em;
    }
  }

  .editor-list {
    .text {
      margin-top: 0.972em;
      margin-bottom: 0.972em;

      @include mq($until: md) {
        margin-top: 0.952em;
        margin-bottom: 0.952em;
      }
    }
  }

  .image,
  .editor-embed {
    margin-top: 60px;
    margin-bottom: 60px;

    @include mq($until: md) {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }

  &--size-m {
    .text {
      @include mq(md) {
        font-size: 28px;
      }
    }
  }

  @include content-margins;
}
