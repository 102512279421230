@use 'src/src/app/scss/abstracts' as *;

.game-page {
  $block-name: &;

  //   &#{$block-name} {
  //   }

  &__game-name {
    display: none;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 15px;

    + #{$block-name} {
      &__characteristics {
        @include mq($until: sm) {
          padding-top: 20px;
        }
      }
    }

    .title {
      font-size: 47px;
    }

    @include mq($until: md) {
      display: block;
    }
  }

  &__intro {
    padding-top: 70px;
    padding-bottom: 70px;

    @include mq($until: md) {
      padding-top: 50px;
      padding-bottom: 50px;
    }

    &:empty {
      display: none !important;
    }
  }

  &__intro-title {
    margin-bottom: 30px;

    @include mq($until: md) {
      .title {
        font-size: 24px;
        line-height: 113%;
        font-weight: $font-weight-l;
      }
    }
  }

  &__intro-image {
  }

  &__more-games {
    padding-top: 40px;
    padding-bottom: 90px;

    @include mq($until: md) {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  &__characteristics {
    @include unlist;
    @include reset-text-indents;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px 40px;
    padding-top: 20px;
    padding-bottom: 20px;

    @include mq($until: sm) {
      gap: 15px;
      flex-wrap: nowrap;
      padding-left: 25px;
      padding-right: 25px;
      text-align: center;
      padding-top: 30px;
    }

    &:empty {
      display: none !important;
    }
  }

  &__characteristics-item {
    display: inline-flex;

    @include mq($until: sm) {
      display: flex;
      flex-direction: column;
      width: 33%;
      text-align: left;
    }
  }

  &__characteristics-icon {
    flex: 0 0 auto;
    margin-top: 2px;
    margin-right: 15px;

    @include mq($until: md) {
      margin-top: 0;
    }

    @include mq($until: sm) {
      margin-right: 0;
    }
  }

  &__characteristics-text {
    color: $color-semantic-third;
    font-family: $font-weight-l;
    font-size: 36px;
    font-style: normal;
    font-weight: 120;
    line-height: 110%;
    letter-spacing: -1.08px;

    @include mq($until: md) {
      margin-top: 8px;
      font-size: $font-size-m;
      line-height: 21px;
      letter-spacing: -0.535px;
    }

    @include mq($until: sm) {
      margin-top: 14px;
    }
  }

  &__main-title {
    padding-top: 70px;
    padding-bottom: 70px;

    @include mq($until: md) {
      padding-top: 45px;
      padding-bottom: 30px;

      .title {
        font-size: 24px;
        line-height: 113%;
        font-weight: $font-weight-l;
      }
    }
  }

  &__download-btn {
    display: flex;
    justify-content: center;

    @include mq($until: sm) {
      display: none;
    }
  }

  &__download-btn-mobile {
    display: none;
    justify-content: center;

    @include mq($until: sm) {
      display: flex;
    }
  }

  &__mobile-button-game {
    display: none;

    @include mq($until: md) {
      padding-top: 15px;
      display: flex;
      justify-content: center;
    }

    @include mq($until: md) {
      .button {
        justify-content: center;
        width: 100%;
        max-width: 100%;
        font-size: 36px;
        height: 65px;
      }
    }
  }
}
