@use 'src/src/app/scss/abstracts' as *;

.password-restore {
  $block-name: &;

  //   &#{$block-name} {
  //   }

  position: relative;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  .form {
    &__form {
      max-width: 400px;
      margin-left: auto;
      margin-right: auto;
    }

    &__form-control {
      margin-top: 25px;

      .button {
        justify-content: center;
        width: 100%;
      }
    }
  }

  &__socials-auth {
    margin-bottom: 50px;

    @include mq($until: md) {
      margin-bottom: 35px;
    }
  }

  &__title {
    margin-bottom: 55px;

    @include mq($until: md) {
      margin-bottom: 25px;
    }
  }

  &__subtitle {
    color: rgb(237 231 255 / 85%);
    margin-bottom: 30px;
    max-width: 425px;
    margin-left: auto;
    margin-right: auto;

    @include mq($until: md) {
      margin-bottom: 15px;
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 55px;

    @include mq($until: md) {
      margin-top: 45px;
    }
  }

  &__link {
    @include reset-btn;

    display: inline-flex;
    margin: -5px;
    padding: 5px;
    color: rgba($color-semantic-third, 0.85);
    font-size: 24px;
    font-style: normal;
    font-weight: $font-weight-m;
    line-height: 120%;
    letter-spacing: -0.48px;
    transition: color $transition-fast;

    @include mq($until: md) {
      font-size: 16px;
    }

    &:not(:last-child) {
      margin-bottom: 15px;

      @include mq($until: md) {
        margin-bottom: 10px;
      }
    }

    &:hover {
      color: $color-base-white-100;
    }

    &:active {
      color: rgba($color-semantic-third, 0.85);
    }
  }

  &__success {
    margin-left: auto;
    margin-right: auto;
  }

  &__success-title {
    margin-bottom: 50px;

    @include mq($until: md) {
      margin-bottom: 25px;
    }
  }

  &__success-message {
    max-width: 405px;
    margin-left: auto;
    margin-right: auto;
  }
}
