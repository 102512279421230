@use 'src/src/app/scss/abstracts' as *;

.studio-layout {
  &__navigation {
  }

  &__main {
    padding-top: 20px;
    padding-bottom: 120px;

    @include mq($until: md) {
      padding-bottom: 70px;
    }
  }
}
