@use 'src/src/app/scss/abstracts' as *;

.gallery {
  $block-name: &;

  //   &#{$block-name} {
  //   }

  &__inner {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: flex-start;

    @include mq($until: md) {
      gap: 8px;
      flex-direction: column;
    }
  }

  &__left,
  &__right {
    display: flex;
    flex-wrap: wrap;
    width: calc(50% - 10px);
    gap: 20px;

    @include mq($until: md) {
      gap: 8px;
      width: 100%;
      max-width: 100%;
    }
  }

  &__left {
    div {
      &:nth-child(1) {
        @include mq($until: md) {
          order: 1;
        }
      }

      &:nth-child(2) {
        @include mq($until: md) {
          order: 3;
        }
      }

      &:nth-child(3) {
        @include mq($until: md) {
          order: 2;
        }
      }
    }
  }

  &__right {
    div {
      &:nth-child(1) {
        @include mq($until: md) {
          order: 2;
        }
      }

      &:nth-child(2) {
        @include mq($until: md) {
          order: 3;
        }
      }

      &:nth-child(3) {
        @include mq($until: md) {
          order: 1;
        }
      }

      &:nth-child(4) {
        @include mq($until: md) {
          order: 4;
        }
      }
    }
  }

  &__cell {
    overflow: hidden;
    border-radius: $border-radius-s;

    @include mq($until: md) {
      border-radius: $border-radius-2xs;
    }

    &--large {
      width: 100%;
      max-width: 100%;
    }

    &--small {
      width: calc(50% - 10px);

      @include mq($until: md) {
        width: calc(50% - 4px);
      }
    }
  }
}
