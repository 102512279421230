@use 'src/src/app/scss/abstracts' as *;

$header-height: 81px;
$header-height-mobile: 67px;

.header-playtest {
  $block-name: &;

  //   &#{$block-name} {
  //   }

  @include reset-text-indents;

  padding-top: 17px;
  padding-bottom: 17px;
  display: flex;
  align-items: center;
  height: $header-height;

  @include mq($until: lg) {
    height: $header-height-mobile;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  &__buttons-group {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-left: auto;
  }

  &__buttons-item {
    margin-left: 4px;
    margin-right: 4px;

    @include mq($until: sm) {
      margin-left: 1px;
      margin-right: 1px;
    }
  }

  &__inner {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  &__logo {
    flex: 0 0 auto;
  }

  &__logo-link {
    display: inline-flex;

    img {
      @include mq($until: lg) {
        width: 106px;
        height: 36px;
      }
    }
  }
}
