@use 'src/src/app/scss/abstracts' as *;

.games-grid {
  $block-name: &;

  //   &#{$block-name} {
  //   }

  &__title {
    margin-bottom: 70px;

    @include mq($until: md) {
      margin-bottom: 30px;
    }
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    @include mq($until: lg) {
      gap: 15px;
    }
  }

  &__cell {
    width: calc(50% - 10px);

    @include mq($until: lg) {
      width: calc(50% - 7.5px);
    }

    @include mq($until: md) {
      width: 100%;
      max-width: 100%;
    }
  }
}
