@use 'src/src/app/scss/abstracts' as *;

.iframe {
  $block-name: &;

  //   &#{$block-name} {
  //   }

  @include reset-text-indents;

  &__inner {
    @include skeleton;

    position: relative;
    z-index: 0;
    overflow: hidden;

    iframe {
      @include position(absolute, 0 0 0 0);

      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__fullscreen-button {
    @include reset-btn;
    @include reset-text-indents;

    position: absolute;
    z-index: 1;
    right: 9px;
    bottom: 9px;
    width: 38px;
    height: 38px;
    transition: color $transition-fast;
    color: rgb(214 208 231 / 100%);
    background-color: rgb(10 6 20 / 20%);
    border-radius: 6px;

    &:hover {
      color: rgb(255 255 255 / 100%);
    }

    &:active {
      color: rgb(214 208 231 / 100%);
    }
  }
}
