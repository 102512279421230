@use 'src/src/app/scss/abstracts' as *;

.editor-list {
  $block-name: &;

  //   &#{$block-name} {
  //   }

  padding-left: 50px;

  @include mq($until: md) {
    padding-left: 30px;
  }

  &--ordered {
    list-style-type: decimal;
  }

  &--unordered {
    list-style-type: square;
  }
}
