@use 'src/src/app/scss/abstracts' as *;

.partners {
  $block-name: &;

  //   &#{$block-name} {
  //   }

  &__inner {
  }

  &__title {
    text-align: center;
    padding-bottom: 60px;

    @include mq($until: md) {
      padding-bottom: 50px;
    }
  }

  &__body {
    @include reset-text-indents;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px 88px;

    @include mq($until: md) {
      gap: 30px 40px;
    }
  }

  &__cell {
    flex: 0 0 auto;
    transition: opacity 0.15s ease;

    &:hover {
      opacity: 0.8;
    }

    @include mq($until: md) {
      max-width: 80px;
      max-height: 40px;
    }
  }
}
