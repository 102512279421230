@use 'src/src/app/scss/abstracts' as *;

.article-card {
  $block-name: &;

  //   &#{$block-name} {
  //   }

  display: block;
  text-decoration: none;

  &:hover {
    #{$block-name} {
      &__media {
        transform: translate3d(0, 0, 0) scale(0.98);
      }
    }
  }

  &:active {
    #{$block-name} {
      &__media {
        transform: translate3d(0, 0, 0) scale(0.96);
      }
    }
  }

  &__media {
    transform: translate3d(0, 0, 0) scale(1);
    transition: transform $transition-slow;
  }

  &__media-inner {
    border-radius: $border-radius-m;
    overflow: hidden;

    @include mq($until: md) {
      border-radius: $border-radius-2xs;
    }
  }

  &__title {
    color: $color-base-purple-200;
    padding: 15px;

    @include mq($until: md) {
      padding: 10px;
    }
  }

  &--loading {
    @include skeleton;

    border-radius: $border-radius-m;
    overflow: hidden;
    pointer-events: none;

    @include mq($until: md) {
      border-radius: $border-radius-2xs;
    }

    #{$block-name} {
      &__media {
        opacity: 0;
      }

      &__title {
        opacity: 0;
      }
    }
  }
}
